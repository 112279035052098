.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #697adb;
  .cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .hidden {
      visibility: hidden;
    }
  }
  canvas {
    width: 20px;
    height: 20px;
  }
}
