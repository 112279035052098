@import '~@/styles/scrollbar.scss';

.home {
  position: absolute;
  top: 24px;
  width: 100%;
  box-sizing: border-box;
  user-select: none;
}

.device-detect {
  margin-top: 15px;
}

.wrapper {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  padding: 24px 48px 24px 48px;
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 12px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;

  color: #1b1f4d;

  img {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  span {
    margin: 0 4px;
    color: #ff5353;
  }
}

.bold-title {
  font-weight: 900;
}

.sub-title {
  margin: 2px 0 0 0;

  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  color: #8d8fa6;
}

.absent {
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;

  .title-box {
    display: flex;

    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    color: #1b1f4d;

    .title {
      position: relative;
      display: flex;
      justify-content: center;
      min-width: 70px;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      cursor: pointer;
      color: #999;

      &-active {
        font-weight: 600;

        color: #333;

        &::after {
          position: absolute;
          bottom: -10px;
          left: 50%;

          width: 50%;
          height: 4px;

          content: '';
          transform: translateX(-50%);
          animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

          border-radius: 3px;
          background: #ff5353;
        }
      }

      &:last-child {
        &::after {
          animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
      }
    }
  }

  .lesson-box {
    box-sizing: border-box;
    width: 100%;
    min-height: 180px;
    margin-top: 20px;
    padding: 20px;

    border: 1px solid #dfe0ed;
    // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
    border-radius: 10px;

    .sub-title {
      display: flex;
      justify-content: space-between;

      margin-top: 0;

      font-size: 15px;
      font-weight: 400;
      line-height: 22px;

      color: rgba(0, 0, 0, 0.6);

      span {
        &:last-child {
          color: #999;
        }
      }
    }
  }

  .tip-box {
    display: flex;
    align-items: center;
    margin-top: 12px;

    span {
      position: relative;
      display: block;
      margin-right: 18px;
      font-size: 16px;
      color: #666;

      &::after {
        position: absolute;
        top: 50%;
        right: -12px;
        width: 1px;
        height: 80%;
        content: '';
        transform: translateY(-50%);
        background-color: #d1d1d1;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .banner-box {
    position: relative;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    padding: 16px;
    border-radius: 10px;
    background: #f7f8fa;

    .box-left {
      display: flex;
      align-items: center;
      margin-right: 12px;
    }

    .box-right {
      display: flex;
      flex-direction: column;

      .prompt {
        display: flex;
        align-items: center;

        width: fit-content;
        margin-top: 12px;
        padding: 8px 16px;

        font-size: 14px;
        font-weight: 400;
        line-height: 24px;

        color: #1b1f4d;
        border: 1px solid #ebecfa;
        border-radius: 0 12px 12px 12px;
        background: #fff;

        span {
          font-size: 14px;
          font-weight: 500;
        }

        .img {
          margin-left: 10px;
        }
      }

      .course-info {
        display: flex;
        margin-top: 16px;
        padding: 12px 16px 12px 12px;
        border: 1px solid #ebecfa;
        border-radius: 0 12px 12px 12px;
        background: #fff;
        box-sizing: border-box;
        align-items: center;

        .intro {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          margin-left: 16px;
          padding: 10px 0;

          .course-title {
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
            color: #1b1f4d;
            margin-bottom: 10px;
          }

          .tag {
            padding: 4px 6px;
            font-size: 13px;
            font-weight: 400;
            line-height: 15px;
            color: #5a6ee0;
            border-radius: 4px;
            background: rgba(90, 110, 224, 0.08);

            &:nth-child(n + 2) {
              margin-left: 8px;
            }
          }
        }
      }
    }

    .left-title {
      display: flex;
      flex-direction: column;

      margin-left: 12px;

      p {
        margin-bottom: 10px;

        font-size: 17px;
        font-weight: 600;
        line-height: 25px;

        &:last-child {
          margin-bottom: 0;

          span {
            margin-right: 8px;
            padding: 2px 4px;

            font-size: 13px;
            font-weight: 400;
            line-height: 25px;

            color: #f2851d;
            border-radius: 4px;
            background: rgba(242, 133, 29, 0.08);
          }
        }
      }
    }

    .submit {
      box-sizing: border-box;
      max-width: 200px;
      height: 44px;
      margin-right: 10px;

      font-size: 14px;
      font-weight: 600;

      background: linear-gradient(90deg, #ff5353 0%, #f88 100%);
      box-shadow: none;
    }
  }

  .ip {
    position: absolute;
    right: 190px;
    bottom: 20px;

    width: 106px;
  }
}

.banner {
  display: flex;
  justify-content: space-between;

  margin-top: 16px;
}

.banner-item {
  flex: 1;

  margin-right: 22px;

  &:last-child {
    margin-right: 0;
  }
}

.subscribe {
  display: flex;
  margin-top: 20px;
}

.subscribe-item {
  justify-content: flex-end;

  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.lectures {
  position: relative;
  margin-top: 20px;
  margin-bottom: 28px;
}

.myCourseTitle {

  a {
    color: #5A6EE0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
}

.homework {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 16px;
  margin-bottom: 28px;

  .homeworkItemWrap {
    border: 1px solid #ebecfa;
    border-radius: 10px;

    .classPreparationWrap {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 16px;

      border-radius: 10px;
      background-color: #f7f8fa;

      .customWrapClass {
        :global {
          .preparationTxt {
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            line-height: 22px; /* 157.143% */

            letter-spacing: 0.5px;

            color: #1b1f4d;
          }

          .preparationBtn {
            padding: 4px 12px;

            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            line-height: 20px; /* 166.667% */

            letter-spacing: 0.5px;

            border-radius: 999px;
          }

          .completed,
          .preparationOrder {
            width: 24px;
            height: 24px;
          }

          .resultLeft {
            position: relative;
            left: -4px;
            .resultTitle {
              font-size: 14px;
              font-weight: 400;
              font-style: normal;
              line-height: 22px; /* 157.143% */

              letter-spacing: 0.5px;

              color: #8d8fa6;
            }

            img {
              width: 42px !important;
            }
          }

          .result {
            border-radius: 10px;
            // background: #fff;
          }

          .preparationItem {
            margin-bottom: 12px !important;
          }

          .preparation-line {
            left: 23px;
          }
        }
      }
    }
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 20px;
    cursor: pointer;
    border: 1px solid #ebecfa;
    border-radius: 10px;
  }

  .homeworkItemWrap:nth-child(n + 2) {
    margin-top: 16px;
  }

  .noneBorder {
    border: 0;
  }

  &-left {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &-right {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .arrow {
      margin-left: 16px;
      display: flex;
    }
  }

  &-sub-title {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #8d8fa6;
  }

  &-title {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    color: #1b1f4d;

    &-name {
      overflow: hidden;
      max-width: 560px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-tip {
      display: flex;
      align-items: center;
    }

    &-more {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      cursor: pointer;
      color: #5a6ee0;

      svg {
        margin-left: 8px;
      }
    }
  }

  &-status {
    display: block;
    margin-right: 8px;
    padding: 4px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    border-radius: 2px;

    &-un-finished {
      color: #ff5353;
      background: rgba(255, 83, 83, 0.06);
    }

    &-finished {
      color: #8d8fa6;
      background: #f7f7f7;
    }

    &-reviewed {
      color: #00cf64;
      background: rgba(0, 207, 100, 0.08);
    }
  }

  &-circle {
    position: relative;
    width: 40px;
    height: 40px;
  }

  &-percent {
    display: block;
    margin-left: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #ff5353;
  }

  &-star {
    width: 24px;
    height: 24px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.lecture-item {
  margin-bottom: 16px;
}

.modal-content {
  padding: 10px;

  border-radius: 58px;
}

.modal-title {
  margin-top: 6px;

  font-size: 18px;
  font-weight: 600;
  line-height: 32px;

  color: #333;
}

.modal-sub-title {
  margin-top: 20px;
  font-size: 16px;
  color: #4d4d4d;
}

.modal-sub-tip {
  margin-top: 12px;

  font-size: 14px;

  color: #999;
}

.btn-group {
  margin-top: 24px;
}

.button {
  margin-right: 32px;

  font-size: 16px;

  border-radius: 170px;

  &:last-child {
    margin-right: 0;
  }
}

.reserve {
  align-self: center;
  margin-left: 40px;
  font-weight: 700;
}

.submit {
  width: 160px;
  height: 54px;

  background-color: #3de38d;
  box-shadow: inset 0 -4px 0 rgba(32, 184, 27, 0.4);
}

.cancel {
  width: 160px;
  height: 54px;
}

.alert {
  min-height: 100px;
  margin-top: 0;

  border-radius: 30px;
}

.modal-body {
  position: fixed;
  z-index: 103;
  top: 50%;
  left: 50%;

  width: 400px;
  min-height: 300px;

  transform: translate(-50%, -50%);

  border-radius: 30px;
  background: #fff;

  &-text {
    margin: 10px 24px;

    font-size: 18px;
    font-weight: 400;
    line-height: 32px;

    text-align: center;

    color: rgba(0, 0, 0, 0.4);
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 215px;
    height: 50px;
    margin: 24px auto 20px auto;

    font-size: 18px;
    font-weight: 500;
    line-height: 25px;

    cursor: pointer;
    text-align: center;

    color: #fff;
    border-radius: 30px;
    background: #ff5353;
  }
}

.absent-status {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #8d8fa6;
}

.new-loading {
  align-self: flex-start;

  width: 50px;
  height: 50px;
  margin-top: 60px;

  border-radius: 0;
}

@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-50%);
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(-100px);
  }

  100% {
    transform: translateX(-50%);
  }
}

.swiper {
  position: relative;

  box-sizing: border-box;
  width: 100%;
  height: 140px;
  margin-top: 20px;
  margin-bottom: -20px;
  padding: 0 2px;
}

.swiper-item-active {
  opacity: 1 !important;
  background-color: #ff5353 !important;
}

.course-title-review {
  color: #8d8fa6;
}

.hide {
  display: none;
}
