.expectationsWrap {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  margin-bottom: 28px;
  padding: 12px;

  border: 1px solid #ebecfa;
  border-radius: 10px;

  @media screen and (max-width: 1023px) {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 16px;
    padding-top: 0;
    margin-bottom: 0;
  }
}

.expectations {
  width: 100%;
  margin-top: 12px;

  gap: 12px;

  .expectationsItem {
    display: flex;
    align-items: center;
    flex: 1;

    height: 60px;
    padding: 0 12px;

    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px; /* 157.143% */

    cursor: pointer;
    letter-spacing: 0.5px;

    color: #1b1f4d;
    border-radius: 10px;
    background-color: #f5f5fc;

    img {
      width: 28px;
      height: 28px;
      margin-right: 8px;
    }

    &:hover {
      background-color: #f0f1fc;
    }
  }

  .expectationsMore {
    display: flex;
    align-items: center;

    height: 60px;

    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px; /* 157.143% */

    cursor: pointer;
    letter-spacing: 0.5px;

    color: #1b1f4d;
    border-radius: 10px;
    background-color: #f5f5fc;
    &:hover {
      background-color: #f0f1fc;
    }

    img {
      width: 20px;
      margin-left: 1px;
    }
  }
}

.tile {
  font-style: normal;
  line-height: 24px; /* 150% */

  color: #1b1f4d;
}

.description {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 22px; /* 157.143% */

  color: #1b1f4d;
}
