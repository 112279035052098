.prepareOperate {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 8px;
}
.checkStep {
  width: 426px;
  .tip {
    color: #4d4d4d;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding-bottom: 20px;
    margin-top: 8px;
    letter-spacing: 0.5px;
    > span {
      color: #cd292a;
    }
  }
  .checkItem {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    .checkInfo {
      display: flex;
      flex-direction: row;
      align-items: center;
      .checkBox {
        display: flex;
        width: 20px;
        height: 20px;
        transform: rotate(-90deg);
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid #ccc;
      }
      .checkText {
        margin-left: 16px;
        color: #0a0a0a;
        font-size: 14px;
        font-weight: 500;
      }
      .checkPass {
        display: flex;
        width: 12px;
        height: 12px;
        padding: 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        background: #3eb173;
      }
    }
    .gotoTest {
      display: flex;
      flex-direction: row;
      display: flex;
      align-items: center;
      .testBtn {
        font-size: 14px;
        font-weight: 400;
        margin-right: 4px;
        cursor: pointer;
        .complete {
          color: #ccc;
        }
        .testNow {
          color: #cd292a;
        }
      }
    }
  }
}
