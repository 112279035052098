@keyframes showing {
  0% {
    opacity: 0;
    height: 0;
  }
  30% {
    opacity: 1;
    height: calc(140 * 0.3);
  }
  60% {
    opacity: 1;
    height: calc(140 * 0.6);
  }
  100% {
    opacity: 1;
    height: 140px;
  }
}
.trialStepsMainContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: #fff;
  position: relative;
  top: -16px;
  overflow: hidden;
  padding: 32px 48px 16px;
  box-sizing: content-box;
  .courseInfo {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 0 0 8px 8px;
    .stepContent {
      padding: 0 24px 24px;
      display: flex;
      flex-direction: row;
      align-items: end;
      justify-content: space-between;
      .stpes {
        width: 607px;
        padding-left: 18px;
        .stepItem {
          position: relative;
          border-left: 1px solid #f0f0f0;
          padding-left: 38px;
          padding-bottom: 24px;
          min-height: 35px;
          &:first-child {
            padding-top: 0px;
            margin-top: 24px;
          }
          &:last-child {
            border-left: 0;
            padding-top: 0px;
            padding-bottom: 10px;
            min-height: 0;
          }
          .stepItemContent {
            padding-top: 8px;
          }
          .stepNumber {
            position: absolute;
            width: 36px;
            height: 36px;
            border-radius: 8px;
            background: linear-gradient(0deg, rgba(205, 41, 42, 0.08) 0%, rgba(205, 41, 42, 0.08) 100%), #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            left: -18px;
            > span {
              color: #cd292a;
              font-size: 16px;
              font-weight: 600;
            }
          }
          .stepBarTitle {
            color: #0a0a0a;
            font-size: 16px;
            font-weight: 600;
            margin-right: 14px;
          }
        }
      }
      .applaudPic {
        display: flex;
        align-items: end;
        animation: showing 500ms;
        -webkit-animation: showing 500ms;
        animation-duration: 500ms;
      }
      .barTitle {
        color: #4d4d4d;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.5px;
        margin-right: 14px;
      }
    }
  }
}
