.rectangleBox {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  width: 307px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 12px;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    flex-direction: row;
    .giftIcon {
      width: 32px;
      height: 32px;
      margin-right: 12px;
      margin-top: 6px;
      display: flex;
      justify-content: center;
    }
    .desc {
      display: flex;
      flex-direction: column;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      .question {
        color: #cd292a;
      }
      .answer {
        color: #0a0a0a;
      }
    }
  }
  .right {
    width: 20px;
    height: 20px;
    padding: 4px;
    background: #fff;
    border-radius: 8px;
    .closeIcon {
      cursor: pointer;
    }
    &:hover {
      border-radius: 8px;
      background: #F5F5F5;
      width: 20px;
      height: 20px;
      padding: 4px;
    }
  }
  
}

.rescheduleBox {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -85px;
  left: 0;
  border-radius: 12px;
  z-index: 999;
  .content {
    position: relative;
    .triangleBox {
      position: absolute;
      left: 0;
      z-index: 99;
      bottom: -11px;
    }
  }
}
