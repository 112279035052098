.teacherHeader {
  width: calc(100% - 48px);
  padding: 24px;
  display: flex;
  flex-direction: row;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .baseInfo {
    display: flex;
    flex-direction: row;
    .avatorBox {
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      width: 60px;
      height: 60px;
      .avator {
        border-radius: 6px;
      }
    }
    .name {
      line-height: 60px;
      margin-left: 24px;
      overflow: hidden;
      color: #0a0a0a;
      text-overflow: ellipsis;
      font-size: 24px;
      font-weight: 800;
      flex: 1;
      max-width: 700px;
    }
  }
  .moreAction {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 28px;
    height: 28px;
    .moreIcon {
      width: 16px;
      height: 16px;
    }
    &:hover {
      border-radius: 99px;
      background: #f5f5f5;
    }
  }
  .dropdownContainer {
    top: 40px;
    right: -60px;
    z-index: 9999;
    width: 126px;
    padding: 0;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
    &:before {
      display: none;
    }
    .funcbox {
      color: #0a0a0a;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.06);
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
      .funcItem {
        padding: 12px 0;
        text-align: center;
        border-bottom: 1px solid #ebecfa;
        cursor: pointer;
        &:last-child {
          border: none;
        }
        &:hover {
          background: #FAFAFA;
          overflow: hidden;
          &:last-child {
            border-radius: 0 0 12px 12px;
          }
          &:first-child {
            border-radius: 12px 12px 0 0;
          }
          &:only-child {
            border-radius: 12px;
          }
        }
      }
    }
  }
}
