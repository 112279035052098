.time-line-container {
  position: relative;
  padding-left: 16px;
  margin-left: 4px;
  padding-bottom: 20px;
  
  &:nth-last-child(n+2) {
    padding-bottom: 20px;
  }
  &::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background: #8D8FA6;
    left: -5px;
    top: 8px;
    border-radius: 4px;
  }
  &.processing::before {
    background: #1B1F4D;
  }
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 4px;
    background-color: #ffffff;
    left: -2px;
    top: 0;
    z-index: 1;
  }
  .title {
    color: #8D8FA6;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 6px;
  }
}

.dot-line {
  position: absolute;
  z-index: -1;
  left: -2px;
  top: 8px;
  height: 100%;
  width: 0;
  border-left: 2px dashed #dfe0ed;
}

.noPaddingBottom {
  padding-bottom: 0;
}