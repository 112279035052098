.trialHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px 12px 0 0;
  background: linear-gradient(0deg, #4c99de 0%, #4c99de 100%), #fff;
  overflow: hidden;
  position: relative;
  .title {
    padding: 28px 40px 34px;
    color: #fff;
    font-size: 24px;
    font-weight: 800;
  }
  .pic {
    position: absolute;
    margin-right: 50px;
    right: 21px;
    top: 0px;
  }
}
