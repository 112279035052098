.classReportOperate {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
}
.checkStep {
  width: 426px;
  .tip {
    color: #4d4d4d;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    letter-spacing: 0.5px;
    > span {
      color: #cd292a;
    }
  }
}

.step3Btn {
  width: 72px;
  height: 17px;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 200px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}
.step3BtnActive {
  color: #fff;
  background-color: #cd292a;
  &:hover {
    background-color: #DB0202;
  }
}
.step3BtnDisable {
  background-color: #F5F5F5;;
  color: #D4D4D4;
}
.step3BtnNormal {
  border-radius: 200px;
  border: 1.5px solid rgba(0, 0, 0, 0.06);
  background: #FFF;
  color: #0A0A0A;
  &:hover {
    border: 1.5px solid rgba(0, 0, 0, 0.06);
    background: #0A0A0A;
    color: #FFF;
  }
}
