.stepNumber {
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  left: -18px;
  top: 0;
  > span {
    font-size: 16px;
    font-weight: 600;
  }
  .redPlot {
    height: 8px;
    width: 8px;
    background-color: #cd292a;
    border-radius: 20px;
    border: 1px solid #fff;
    position: absolute;
    right: -3px;
    top: -3px;
  }
}
.taskNormal {
  background: #f5f5f5;
  > span {
    color: #0a0a0a;
  }
}
.taskCompleted {
  width: 14px;
  height: 14px;
  position: absolute;
  padding: 10px;
  border-radius: 8px;
  background: #3eb173;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -18px;
  top: 0;
}
.taskProcessing {
  background: linear-gradient(0deg, rgba(205, 41, 42, 0.08) 0%, rgba(205, 41, 42, 0.08) 100%), #fff;
  > span {
    color: #cd292a;
  }
}
