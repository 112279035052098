.wrong-question {
  margin-bottom: 28px;

  h2 {
    margin-bottom: 20px;

    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    text-align: left;
    letter-spacing: 0;

    color: #1b1f4d;
  }

  .question-row {
    display: flex;
    align-items: center;
  }

  .question-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 16px;
    padding: 23px 20px;

    border: 1px solid #ebecfa;
    border-radius: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .p1 {
      margin-right: 12px;
      padding: 4px 6px 4px 6px;

      font-size: 12px;
      font-weight: 400;
      line-height: 14px;

      text-align: left;
      letter-spacing: 0;

      color: #5a6ee0;
      border-radius: 2px;
      background-color: #f5f5fc;
    }
    .p1-zh {
      padding: 1px 8px;
      margin-right: 16px;
      line-height: 20px;
    }

    .p2 {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;

      text-align: left;
      letter-spacing: 0;

      color: #1b1f4d;

      b {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;

        text-align: left;
        letter-spacing: 0;

        color: #FF5353;
      }
    }

    .correct-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      height: 28px;
      padding-right: 12px;
      padding-left: 12px;

      font-size: 12px;
      font-weight: 500;
      line-height: 14px;

      cursor: pointer;
      text-align: center;
      text-decoration: none;
      letter-spacing: 0.5px;

      color: #fff;
      border: 0;
      border-radius: 15px;
      outline: none;
      background-color: #ff5353;

      -webkit-tap-highlight-color: transparent;
    }
  }
}
