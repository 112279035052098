@import '@/styles/mediaQuery';
html:lang(zh) .container *,
body:lang(zh) .container * {
  font-family: 'MiSans', 'PingFang SC', sans-serif !important;
}
html:lang(en) .container *,
body:lang(en) .container * {
  font-family: 'Figtree', 'PingFang SC', sans-serif !important;
}
.container {
  border-radius: 12px;
}

.cover {
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  line-height: 36px;
  border-radius: 6px;
  background: #F5F5FC;
  font-size: 14px;
  color: #484C70;
}
.coverBtn{
  color: #5A6EE0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin-left: 10px;
}
.coverTitle {
  color: #1B1F4D;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}
.coverImg {
  width: 28px;
  height: 28px;
  margin: 0 8px 0 4px;
}
.footer {
  display: flex;
  padding: 16px;
  background-color: #F5F5F5;
  border-radius: 10px;
  background-image: url('/imgs/esSubscriptionBoxBg-s.png');
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  position: relative;
  .teacherDesM {
    display: none;
  }
  @include mediaS {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    // box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 20px 0px;
    margin-top: 24px;
    padding: 20px;
    .teacherDesP {
      display: none;
    }
    .teacherDesM {
      display: flex;
      align-items: center;
      padding: 12px;
      border-radius: 2px 16px 16px 16px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      background: #FAFAFA;
      margin-bottom: 16px;
      color: #0A0A0A;
      font-size: 16px; 
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }

}
.footerS {
  background-image: url('/imgs/esSubscriptionBoxBg-l.png');
  background-size: 100% 100%;
}
.footerInfo {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include mediaS {
    text-align: center;
  }
}
.footerInfoTitle {
  color: #0A0A0A;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 10px;
 
}
.footerInfoDes {
  color: #4D4D4D;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @include mediaS {
    margin-bottom: 20px;
  }
}

.footerTeacherInfo {
  width: 100%;
  display: flex;
  @include mediaS {
    margin-bottom: 16px;
  }
}
.avatarImg {
  width: 60px;
  height: 60px;
  border-radius: 6px;
  margin-right: 16px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  z-index: 2;
}
.teacherName {
  color: #0A0A0A;
  text-overflow: ellipsis;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  width: 600px;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
  @include mediaS {
    margin-top: 6px;
    width: 200px;
  }
}
.teacherLevel {
  color: #0A0A0A;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 6px 0 10px 0;
  @include mediaS {
    padding: 6px 0 0 0;
  }
}
.teacherDes {
  color: #0A0A0A;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 600px;
}

.footerInfoBtn {
  width: 112px;
  height: 41px;
  border-radius: 200px;
  border: 1.5px solid #4D4D4D;
  color: #0A0A0A;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  min-width: 112px;
  padding: 12px 24px;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #fff;
  &:hover {
    border: 1.5px solid #4D4D4D;
    color: #fff;
    background-color: #0A0A0A;
  }
}
.footerInfoBtnComplate {
  background-color: #CD292A;
  color: #FFF;
  border: none !important;
  box-sizing: border-box;
  &:hover {
    background-color: #DB0202;
    border: none !important;
  }
}

.bgImgTop {
  display: none;
  @include mediaS {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 54px;
    height: 50px;
  }
}
.bgImgBottom {
  display: none;
  @include mediaS {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 140px;
    height: 60px;
  }
}

.hide {
  display: none !important;
}
