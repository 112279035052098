.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;

  color: #1b1f4d;

  img {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  span {
    margin: 0 4px;
    color: #ff5353;
  }
}

.viewMore {
  color: #5a6ee0 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.wrap {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.container {
  width: 100%;
}

.subscription {
  display: flex;
  justify-content: space-between;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  padding: 14px 20px;
  background: #F5F5FC;

  .subscriptionLeft,
  .subscriptionRight {
    position: relative;
    z-index: 1;
  }
  .subscriptionLeft {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  h2 {
    color: #1b1f4d;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */

    // strong {
    //   background: linear-gradient(90deg, #ff5353 14.29%, #6d38fb 63.38%, #009dff 99.65%);
    //   background-clip: text;
    //   -webkit-background-clip: text;
    //   -webkit-text-fill-color: transparent;
    // }
  }

  h4 {
    color: #8d8fa6;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 6px;
    line-height: 22px; /* 157.143% */
  }

  .tags {
    display: flex;
    gap: 12px;
    margin-bottom: 28px;
    span {
      display: flex;
      padding: 6px 16px;
      align-items: center;
      gap: 8px;
      border-radius: 99px;
      color: #1b1f4d;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: linear-gradient(
        90deg,
        rgba(255, 83, 83, 0.08) 8.55%,
        rgba(109, 56, 251, 0.08) 61.14%,
        rgba(0, 157, 255, 0.08) 100%
      );
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 120px;
    border-radius: 999px;
    border: 1px solid #FFF;
    background: linear-gradient(90deg, #FF5353 -0.43%, #6D38FB 57.13%, #009DFF 99.65%);
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    z-index: 1;
    cursor: pointer;
    span {
      z-index: 2;
    }
    // &::after {
    //   position: absolute;
    //   left: 0;
    //   top: 4px;
    //   width: 100%;
    //   height: 36px;
    //   content: '';
    //   border-radius: 999px;
    //   background: linear-gradient(
    //     90deg,
    //     rgba(255, 83, 83, 0.4) 8.55%,
    //     rgba(109, 56, 251, 0.4) 61.14%,
    //     rgba(0, 157, 255, 0.4) 100%
    //   );
    //   filter: blur(10px);
    // }
  }

  .subscriptionRight {
    display: flex;
    position: absolute;
    right: 27px;
    bottom: 14px;
    img {
      height: 171px;
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
      &:nth-of-type(2) {
        margin-right: 12px;
      }
    }
  }
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.subscriptionCourse {
  margin-bottom: 28px;

  &:last-child {
    margin-bottom: 0;
  }
}

.modal {
  padding: 24px;
  width: 292px;
  h2 {
    text-align: center;
    color: #1b1f4d;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    margin-bottom: 24px;
  }
  ul {
    list-style: none;
    text-align: center;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      height: 56px;
      border-radius: 8px;
      box-sizing: border-box;
      color: #1b1f4d;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 160% */
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      cursor: pointer;
    }
  }

  .chinese {
    background: url('/imgs/coursePackage/ch-default.svg') 50% 50% no-repeat;
    // &:hover {
    //   border: 1px solid rgba(255, 176, 83, 0.81);
    // }
  }
  .math {
    background: url('/imgs/coursePackage/mt-default.svg') 50% 50% no-repeat;
    // &:hover {
    //   border: 1px solid rgba(0, 178, 255, 0.77);
    // }
  }
  .english {
    background: url('/imgs/coursePackage/en-default.svg') 50% 50% no-repeat;
    // &:hover {
    //   border: 1px solid rgba(248, 66, 186, 0.51);
    // }
  }
  .chinese-active {
    background: linear-gradient(277.61deg, rgba(255, 176, 83, 0.81) -3.09%, #ff5353 56.31%, #fc3256 100.16%);
    color: #ff5353;
  }
  .math-active {
    background: linear-gradient(276.37deg, rgba(0, 179, 255, 0.77) -0.81%, #0092ff 43.33%, #0038ff 100.14%);
    color: #0038ff;
  }
  .english-active {
    background: linear-gradient(274.01deg, rgba(248, 66, 186, 0.51) 0.09%, #8642f8 45.06%, #4642f8 100.09%);
    color: #4642f8;
  }

  .chinese-hover {
    &:hover {
      background: linear-gradient(
        277.61deg,
        rgba(255, 176, 83, 0.081) -3.09%,
        rgba(255, 83, 83, 0.1) 56.31%,
        rgba(252, 50, 86, 0.1) 100.16%
      );
      color: #ff5353;
    }
  }
  .math-hover {
    &:hover {
      background: linear-gradient(
        276.37deg,
        rgba(0, 179, 255, 0.077) -0.81%,
        rgba(0, 146, 255, 0.1) 43.33%,
        rgba(0, 56, 255, 0.1) 100.14%
      );
      color: #0038ff;
    }
  }
  .english-hover {
    &:hover {
      background: linear-gradient(
        274.01deg,
        rgba(248, 66, 186, 0.051) 0.09%,
        rgba(134, 66, 248, 0.1) 45.06%,
        rgba(70, 66, 248, 0.1) 100.09%
      );
      color: #4642f8;
    }
  }

  .closeIcon {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
  }

  .active {
    border-radius: 8px;
    border: 1px solid rgba(255, 176, 83, 0.81);
  }
  button {
    width: 100%;
    display: flex;
    padding: 12px 60px;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    border: 0;
    background: linear-gradient(90deg, #ff5353 8.55%, #6d38fb 61.14%, #009dff 100%);
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 32px;
    cursor: pointer;
  }
}

// border-gradient
.gradient-border {
  position: relative;
  border-radius: inherit;
  overflow: hidden;
  width: 100%;
  height: 100%;
  & > * {
    position: relative;
    z-index: 2;
  }
  &::before {
    content: '';
    position: absolute;
    top: 1px; /* Adjust the value to change the border thickness */
    left: 1px;
    right: 1px;
    bottom: 1px;
    background: white;
    border-radius: inherit;
  }
  > p {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.subscribeButtonWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  .hand {
    position: absolute;
    right: -20px;
    bottom: -10px;
    z-index: 1;
  }
}