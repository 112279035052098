.subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  border: 1px solid #dfe0ed;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.cover {
  position: relative;
  width: 100%;
}

.cover-info {
  width: 55%;
  position: absolute;
  left: 0;
  top: 0;
  background: #f7f7f7;
  clip-path: polygon(0 0, 88% 0, 100% 100%, 0% 100%);
  z-index: 1;
}

.cover-img {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.banner-title {
  color: #1b1f4d;
  font-weight: 700;
}

.banner-line {
  background: #115aff;
  height: 2px;
}

.footer {
  height: 52px;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px 0 18px;
  box-sizing: border-box;
}

.title {
  font-weight: 500;
  font-size: 14px;
  color: #1b1f4d;
}

.button {
  min-width: 77px;
  height: 28px;
  border-radius: 30px;
  box-shadow: none;
  cursor: pointer;
  font-size: 12px;
  border: 1px solid #dfe0ed;
  background: #fff;
  color: #1b1f4d;
  font-weight: 500;
  &:active,
  &:hover {
    background: #f7f8fa;
  }
  &:hover {
    &:active {
      box-shadow: none;
      transform: scale(1);
    }
  }
  &-chinese,
  &-math,
  &-english {
    &-gray {
      color: #c6c7d2;
      border: 1px solid #F0F1FC;
      &:active,
      &:hover {
        background: #fff;
        color: #c6c7d2;
      }
    }
  }
}
.appointment {
  &-TRAILED {
    .title {
      // color: #8d8fa6;
    }
  }
}
.noBtn {
  cursor: pointer;
  color: #5A6EE0;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
}

.trailed {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: #C6C7D2;
}
.subscribe-ENGLISH {
  .logo {
    color: #ae00ff;
    fill: #ae00ff;
  }
  .banner-line {
    background: #ae00ff;
  }
}

.subscribe-MATH {
  .logo {
    color: #115aff;
    fill: #115aff;
  }
  .banner-line {
    background: #115aff;
  }
}

.subscribe-CHINESE {
  .logo {
    color: #ff5353;
    fill: #ff5353;
  }
  .banner-line {
    background: #ff5353;
  }
}

.subscribe-size-S {
  .banner-sub-title {
    color: #1b1f4d;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    opacity: .9;
    
    &.zh {
      padding-top: 6px;
    }
  }
  .cover {
    min-height: 132px;
  }
  .cover-info {
    height: calc(100% - 15px);
    padding: 16px 12px 0 16px;
    clip-path: polygon(0 0, 84% 0, 100% 100%, 0% 100%);
  }
  .banner-title {
    font-size: 16px;
    line-height: 24px;
  }
  .banner-line {
    width: 40px;
    margin: 4px 0 6px 0;
  }
  .logo {
    width: 52px;
    height: 21px;
    margin-bottom: 6px;
  }
  .cover-img {
    right: -70px;
  }
}

.subscribe-size-M {
  .banner-sub-title {
    color: #1b1f4d;
    font-size: 14px;
    font-weight: normal;
    line-height: 21px;
    opacity: .9;
    width: 200px;
  }
  .cover {
    height: 160px;
  }
  .cover-info {
    height: 100%;
    padding: 20px 9% 0 20px;
    box-sizing: border-box;
  }
  .banner-title {
    font-size: 22px;
    line-height: 26px;
  }
  .banner-line {
    width: 40px;
    margin: 10px 0 8px 0;
  }
  .logo {
    width: 60px;
    height: 24px;
    margin: 0 0 8px 0;
  }
  .cover-img {
    right: -50px;
  }
}

.subscribe-size-L {
  .cover {
    height: 200px;
  }
  .cover-info {
    height: calc(100% - 27px);
    padding: 28px 12px 0 30px;
  }
  .banner-title {
    font-size: 26px;
    line-height: 40px;
  }
  .banner-sub-title {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    color: #1b1f4d;
    opacity: .9;
  }
  .banner-line {
    width: 60px;
    margin: 12px 0 16px 0;
  }
  .footer {
    height: 56px;
  }
  .title {
    font-size: 16px;
  }
  .logo {
    width: 80px;
    height: 28px;
    margin-bottom: 16px;
  }
  .noBtn {
    font-size: 14px;
    line-height: 22px;
  }
}
