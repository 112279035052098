.joinClassOperate {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0 24px;
  .descList {
    padding: 0 24px 0 24px;
  }
  .desc {
    margin: 0;
    color: #0a0a0a;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
    > span {
      color: #cd292a;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .btnBox {
    position: relative;
    margin-top: 8px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    .step1Btn {
      width: 130px;
      height: 17px;
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 200px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      .classing {
        margin-left: 4px;
      }
    }
    .step1BtnBefore {
      background-color: #f5d4d4;
      color: #fff;
    }
    .step1BtnActive {
      color: #fff;
      background-color: #cd292a;
      &:hover {
        background-color: #db0202;
      }
    }
    .step1BtnDisable {
      background-color: #f5f5f5;
      color: #d4d4d4;
    }
  }
}
