.collapseBar {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.collapseBox {
  width: 100%;
  :global {
    .ReactCollapse--collapse {
      transition: height 500ms;
    }
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

