.wrapper {
  position: relative;

  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;

  &.border {
    padding: 18px 28px 20px 24px;

    border: 1px solid #dfe0ed;
    border-radius: 10px;
    // &::after {
    //   position: absolute;
    //   content: '';
    //   top: 20px;
    //   left: -1px;
    //   width: 3px;
    //   height: 16px;
    //   background: rgba(198, 199, 210, 0.5);
    //   border-top-right-radius: 2px;
    //   border-bottom-right-radius: 2px;
    // }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.point {
    padding-left: 18px
  }

  &.point:before {
    position: absolute;
    top: 6px;
    left: 0;

    width: 6px;
    height: 6px;

    content: '';

    border-radius: 50%;
    background: #484c70;
  }

  &.active:before {
    background: #ff5353;
  }

  &.divider {
    padding-bottom: 24px;

    color: #dfe0ed;
  }

  &.divider:after {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;

    content: '';

    border-radius: 10px;
    background: #dfe0ed;
  }

  .course-wrapper {
    display: flex;
    flex-direction: column;

    max-width: 460px;
    
    .header {
      display: flex;
      align-items: center;

      margin-bottom: 8px;

      font-size: 12px;
      line-height: 20px;

      .time-container {
        font-weight: 500;

        color: #1b1f4d;
      }

      .day-container {
        font-weight: 400;

        color: #8d8fa6;
      }
    }

    .info {
      display: flex;
      height: 20px;
      align-items: center;

      margin-bottom: 7px;

      font-size: 12px;
      font-weight: 400;
      //margin-bottom: 7px;
      white-space: nowrap;

      color: #8d8fa6;

      &.no-header {
        //margin-bottom: 7px;
      }
    }

    .course {
      display: flex;
      color: #1b1f4d;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 4px;
    }

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      border-radius: 50%;
    }

    .divider {
      margin: 0 8px;
      color: #DFE0ED;
    }

    .sub-title {
      overflow: hidden;

      max-width: 340px;

      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .name {
      max-width: 140px;

      @extend .sub-title;
    }
  }

  .operate-container {
    display: flex;
    align-items: center;

    .button {
      display: flex;
      align-items: center;

      height: 28px;
      margin-left: 8px;
      padding: 0 12px;

      font-size: 12px;
      font-weight: 500;

      cursor: pointer;
      white-space: nowrap;

      color: #1b1f4d;
      border: 1px solid #ebecfa;
      border-radius: 14px;

      &.active {
        color: #fff;
        background-color: #ff5353;
      }

      &.processing {
        &::before {
          width: 12px;
          height: 12px;
          margin-right: 6px;

          content: '';

          background-image: url('/imgs/pc/processing-icon.gif');
          background-position: center;
          background-size: contain;
        }
      }

      &.disabled {
        color: #8d8fa6;
      }
    }
  }

  .hour-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  .processing-title {
    font-weight: 500;

    color: #5a6ee0;;
  }
}
