@import "~@/styles/color.scss";

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  &.border {
    // border: 1px solid #DFE0ED;
    // border-radius: 10px;
    // padding: 18px 28px 20px 24px;
    // &::after {
    //   position: absolute;
    //   content: '';
    //   top: 20px;
    //   left: -1px;
    //   width: 3px;
    //   height: 16px;
    //   background: rgba(198, 199, 210, 0.5);
    //   border-top-right-radius: 2px;
    //   border-bottom-right-radius: 2px;
    // }
  }
  &.point {
    padding-left: 20px
  }
  &.point:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background: #8D8FA6;
    top: 6px;
    left: 0;
    border-radius: 50%;
  }
  &.active:before {
    background: #FF5353;
  }
  &.divider {
    color: #DFE0ED;
    padding-bottom: 24px;
  }
  &.divider:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    background: $divider_1;
    border-radius: 10px;
  }
  .course-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 460px;
    .header {
      display: flex;
      font-size: 12px;
      align-items: center;
      line-height: 22px;

      .time-container {
        color: #1b1f4d;
        font-weight: 500;
        font-size: 14px;
      }
      .day-container {
        color: #8d8fa6;
        font-weight: 400;
      }
    }
    .info {
      display: flex;
      align-items: center;
      color: #8d8fa6;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      white-space: nowrap;
      margin-top: 8px;
      &.no-header {
        margin-bottom: 7px;
      }
    }
    .course {
      display: flex;
      color: #8D8FA6;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      margin-top: 2px;  
    }
    .icon {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 8px;
    }
    .divider {
      margin: 0 10px;
      color: #DFE0ED;
    }
    .sub-title {
      max-width: 340px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .name {
      @extend .sub-title;
      max-width: 140px;
    }
  }
  .operate-container {
    display: flex;
    align-items: center;
    .button {
      color: #1B1F4D;
      display: flex;
      align-items: center;
      padding: 0 12px;
      height: 28px;
      font-size: 12px;
      font-weight: 500;
      border: 1px solid #EBECFA;
      border-radius: 14px;
      white-space: nowrap;
      margin-left: 8px;
      cursor: pointer;
      &.active {
        color: #ffffff;
        background-color: #FF5353;
      }
      &.processing {
        &::before {
          content: '';
          width: 12px;
          height: 12px;
          margin-right: 6px;
          background-image: url('/imgs/pc/processing-icon.gif');
          background-size: contain;
          background-position: center;
        }
      }
      &.disabled {
        color: #8D8FA6;
      }
    }
  }

  .hour-title {
    color: #484C70;
    font-weight: 500;
  }
  .processing-title {
    color: #5A6EE0;
    font-weight: 500;;
  }
}
