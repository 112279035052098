.trialCourseItemColumn {
  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #f0f0f0;
  .item {
    flex: 1;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    border-collapse: collapse;
    > span {
      color: #0a0a0a;
      font-size: 14px;
      font-weight: 400;
      margin-left: 24px;
    }
    >img {
      width: 28px;
      height: 28px;
    }
  }
  .borderRight {
    border-right: 1px solid #f0f0f0;
  }
}
