.container {
  margin-top: 28px;
  margin-bottom: 28px;

  .title {
    margin-bottom: 20px;

    font-size: 16px;
    font-weight: 800;
    line-height: 24px;

    color: #1b1f4d;
  }

  .content {
    padding: 8px 8px 0;

    border-radius: 10px;
    background-color: #ffe7d0;

    .white-scope {
      border-radius: 10px;
      background-color: #fff;

      .view-report {
        display: flex;
        align-items: center;

        padding: 0 16px;

        .left {
          margin-right: 36px;

          > p {
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;

            color: #1b1f4d;

            &:nth-last-child(1) {
              margin-top: 4px;

              font-size: 12px;
              font-weight: 400;
              line-height: 14px;

              color: #8d8fa6;
            }
          }
        }

        .seal {
          width: 72px;
          height: 72px;

          background-size: cover;

          &-zh {
            background: url('~@public/imgs/pc/home/entryLine/seal_zh.svg') no-repeat;
          }

          &-en {
            background: url('~@public/imgs/pc/home/entryLine/seal_en.svg') no-repeat;
          }
        }

        .vr-btn {
          height: 28px;
          margin-left: auto;
          padding: 0 19px;

          font-size: 12px;
          font-weight: 500;
          line-height: 28px;

          cursor: pointer;
          text-align: center;

          color: #fff;
          border-radius: 15px;
          background: #ff5353;
        }
      }

      .recommend {
        display: flex;
        align-items: center;

        height: 120px;

        .left-scope {
          display: flex;
          align-items: center;
          flex: 1;
          justify-content: center;

          height: 100%;

          .leftScopInner {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 342px;
            margin: 0 auto;
          }

          .text {
            margin: 0 40px;

            text-align: center;

            > p {
              font-size: 14px;
              font-weight: 600;
              line-height: 21px;

              word-break: break-all;

              color: #1b1f4d;

              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              

              &:nth-last-child(1) {
                margin-top: 8px;

                font-size: 12px;
                font-weight: 400;
                line-height: 18px;

                color: #8d8fa6;
              }
            }
          }
        }

        .line {
          width: 1px;
          height: 80px;

          background: #ebecfa;
        }

        .right-scope {
          position: relative;

          display: flex;
          align-items: center;
          flex: 1;
          justify-content: center;

          height: 100%;

          &-content {
            width: 318px;

            > p {
              font-size: 12px;
              line-height: 18px;

              text-align: center;

              color: #8d8fa6;
            }

            .special {
              display: flex;
              align-items: center;
              justify-content: space-between;

              height: 32px;
              margin-top: 16px;
              padding: 0 20px;

              border-radius: 20px;
              background: #f5f5fc;

              .p1 {
                overflow: hidden;

                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;

                text-align: center;
                white-space: nowrap;
                letter-spacing: 0;
                text-overflow: ellipsis;
              }

              .dot {
                position: absolute;

                width: 24px;
                height: 20px;

                background-size: cover;

                &:nth-of-type(1) {
                  right: 10px;
                  bottom: 10px;

                  background: url('~@public/imgs/pc/home/entryLine/left-dot.svg') no-repeat;
                }

                &:nth-of-type(2) {
                  top: 10px;
                  left: 10px;

                  background: url('~@public/imgs/pc/home/entryLine/right-dot.svg') no-repeat;
                }
              }
            }
          }
        }
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 44px;

      gap: 154px;

      .item {
        display: flex;
        align-items: center;

        cursor: pointer;
        transition: all .3s ease-in-out;

        &:hover {
          opacity: .8;
        }

        > p {
          margin-left: 4px;

          font-size: 14px;
          font-weight: 400;

          color: #7a6d61;
        }
      }
    }
  }
}
