html:lang(zh) .trialContainer *,
body:lang(zh) .trialContainer * {
  font-family: 'MiSans', 'PingFang SC', sans-serif !important;
}
html:lang(en) .trialContainer *,
body:lang(en) .trialContainer * {
  font-family: 'Figtree', 'PingFang SC', sans-serif !important;
}
.trialCourseContent {
  background-color: #fff;
  border-radius: 12px;
}
