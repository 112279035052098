.mask {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  z-index: 2000;
  overflow: auto;
}

.hidden {
  display: none;
}

.container {
  box-sizing: border-box;
  width: 340px;
  padding: 24px 24px 24px 24px;
  border-radius: 20px;
  background: #fff;
  position: relative;
}

.container.hasBer {
  padding-top: 52px;
}

.header {
  margin-bottom: 8px;
}

.title {
  color: #1b1f4d;
  text-align: center;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: center;
}

.content {
  margin-bottom: 24px;
  color: #8d8fa6;
  text-align: center;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.cancelBtnWrap {
  flex: 1 0 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: #f5f5fc;
  cursor: pointer;
}

.okBtnWrap {
  flex: 1 0 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: #ff5353;
  cursor: pointer;
}

.cancelText {
  color: #484c70;
  text-align: center;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.okText {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.close {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
}

.berWrap {
  width: 152px;
  height: 92px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -52px;
}