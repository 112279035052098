.wrapper {
  margin-bottom: 28px;

  border-radius: 10px;
  background-color: #f5f5fc;
}

.inner {
  position: relative;

  display: flex;

  box-sizing: border-box;
  width: 100%;
  padding: 14px 20px 12px 20px;

  background-image: url(/imgs/pc/icon-circle.svg), url(/imgs/pc/icon-circle.svg), url(/imgs/pc/icon-circle.svg);
  background-repeat: no-repeat;
  background-position: -50px -50px, center 46px, calc(100% + 50px) -54px;
}

.ip {
  position: absolute;
  bottom: 0;
}

.wrapper-left {
  display: flex;
  align-items: center;
  flex: 1;
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  color: #1b1f4d;
}

.tip {
  margin-top: 6px;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  color: #8d8fa6;
}

.icon {
  position: absolute;
  top: 4px;
  left: 400px;

  width: 40px;
  height: 40px;
}

.wrapper-right {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.verify {
  display: flex;
  align-items: center;

  padding: 10px 20px;

  font-size: 16px;
  font-weight: 500;
  line-height: 22px;

  cursor: pointer;

  color: #23aaff;
  border-radius: 20px;
  background: #fff;
}

.verify-btn {
  font-size: 12px;
  font-weight: 500;
}

.lock {
  position: relative;

  display: flex;

  margin-right: 20px;
  margin-left: 20px;
  padding: 12px 0;

  border-top: 1px solid #ebecfa;

  .lockItem {
    display: flex;
    align-items: center;

    margin-right: 40px;

    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;

    color: #484c70;

    /* 157.143% */
    img {
      margin-right: 4px;
    }
  }
}
