.lecture {
  position: relative;

  box-sizing: border-box;
  height: 204px;
  padding: 16px 28px;

  border: 1px solid #fafafa;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
}

.nav {
  display: flex;
  justify-content: space-between;

  height: 34px;

  border-bottom: 1px solid #ededed;
}

.start-time {
  flex: 1;

  font-size: 16px;
  font-weight: 600;
  font-style: normal;

  color: #333;
}

.status {
  flex: 1;
}

.tip-box {
  display: flex;
  align-items: center;
  align-items: center;

  margin-top: 12px;

  span {
    position: relative;

    display: block;

    margin-right: 21px;

    font-size: 14px;

    color: #999;

    &::after {
      position: absolute;
      top: 50%;
      right: -11px;

      width: 1px;
      height: 80%;

      content: '';
      transform: translateY(-50%);

      background-color: #e6e6e6;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

.tip-icon {
  margin-right: 8px;
}

.title {
  margin-top: 16px;

  font-size: 18px;
  font-weight: 600;

  color: #333;
}

.info {
  display: flex;
  justify-content: space-between;

  margin-top: 16px;
}

.teacher {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.name {
  font-size: 14px;

  opacity: .5;
  color: #333;
}

.operate {
  display: flex;
  align-items: center;
}

.join-tip {
  display: flex;
  align-items: center;

  margin-right: 20px;

  font-size: 14px;

  cursor: pointer;

  color: #333;

  img {
    width: 14px;
    height: 14px;
    margin-left: 4px;
  }
}

.button {
  width: 108px;
  height: 36px;
  margin-right: 12px;

  border-radius: 25px;

  &::after {
    margin-right: 0;
  }
}

.qr {
  display: flex;
  align-items: center;

  height: 104px;
  margin-top: -60px;
  padding: 0 20px;
  padding-top: 45px;

  border-radius: 0 0 28px 28px;
  background: #fff5f5;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .06);

  img {
    width: 72px;
    height: 72px;
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin-left: 15px;
    padding: 12px 0;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    color: #999;

    span {
      margin-bottom: 8px;

      font-size: 18px;
      font-weight: 600;
      line-height: 25px;

      color: #333;
    }
  }
}

.hour-title {
  font-weight: 500;

  color: #484c70;
}

.processing-title {
  font-weight: 500;

  color: #5a6ee0;
}

.divider {
  margin: 0 10px;

  color: #dfe0ed;
}

.container {
  padding: 20px;

  border: 1px solid #ebecfa;
  border-radius: 10px;

  & + & {
    margin-top: 16px;
  }
}

.classPreparationWrap {
  margin-left: 20px;
  padding: 16px;

  border-radius: 10px;
  background-color: #f7f8fa;

  .customWrapClass {
    :global {
      .preparationTxt {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 22px; /* 157.143% */

        letter-spacing: .5px;

        color: #1b1f4d;
      }

      .preparationBtn {
        padding: 4px 12px;

        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        line-height: 20px; /* 166.667% */

        letter-spacing: .5px;

        border-radius: 999px;
      }

      .completed, .preparationOrder {
        width: 24px;
        height: 24px;
      }

      .resultLeft {
        position: relative;
        left: -4px;
        .resultTitle {
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          line-height: 22px; /* 157.143% */

          letter-spacing: .5px;

          color: #8d8fa6;
        }

        img {
          width: 42px !important;
        }
      }

      .result {
        border-radius: 10px;
        // background: #fff;
      }

      .preparationItem {
        margin-bottom: 12px !important;
      }

      .preparation-line {
        left: 23px;
      }
    }
  }
}

.unactive {
  background-color: #FFCFD5 !important;
}