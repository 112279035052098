$font-size-cus: 14px;


.notice {
  width: 100%;
  height: 36px;
  padding: 0 8px 0 4px;
  border-radius: 6px;
  background: #f5f5fc;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  &-icon {
    flex: 0 0 auto;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-main {
    flex: 1 1 auto;
    padding-right: 20px;
    display: flex;
    align-items: center;

    .title {
      flex: 0 0 auto;
      color: #1b1f4d;
      font-size: $font-size-cus;
      line-height: 22px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
    }

    .content {
      flex: 1 1 auto;
      overflow: hidden;
      color: #484c70;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: $font-size-cus;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &-btn {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    .text {
      color: #5a6ee0;
      font-size: $font-size-cus;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

