.infoContent {
  padding: 52px 24px 24px;
  position: relative;

  .headerImg {
    position: absolute;
    top: -68px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 108px;
  }

  .description {
    color: #1B1F4D;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .btn {
      display: flex;
      height: 40px;
      width: 136px;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      font-size: 16px;
      cursor: pointer;
    }

    .cancelBtn {
      background-color: #F5F5FC;
      color: #484C70;
    }

    .sureBtn {
      background-color: #FF5353;
      color: #fff;
    }
  }
}

.toolTipModal {
  width: 340px;
  border-radius: 20px;

  & > div {
    padding: 0 !important;
  }
}